import KTUtil from "./util"
import KTEventHandler from "./event-handler"

"use strict";

var KTDrawerHandlersInitialized = false;

// Class definition
var KTDrawer = function (element, options) {
  //////////////////////////////
  // ** Private variables  ** //
  //////////////////////////////
  var the = this;

  if (typeof element === "undefined" || element === null) {
    return;
  }

  // Default options
  var defaultOptions = {
    overlay: true,
    direction: "end",
    baseClass: "drawer",
    overlayClass: "drawer-overlay",
  };

  ////////////////////////////
  // ** Private methods  ** //
  ////////////////////////////

  var _construct = function () {
    if (KTUtil.data(element).has("drawer")) {
      the = KTUtil.data(element).get("drawer");
    } else {
      _init();
    }
  };

  var _init = function () {
    // Variables
    the.options = KTUtil.deepExtend({}, defaultOptions, options);
    the.uid = KTUtil.getUniqueId("drawer");
    the.element = element;
    the.overlayElement = null;
    the.name = the.element.getAttribute("data-kt-drawer-name");
    the.shown = false;
    the.lastWidth;
    the.toggleElement = null;

    // Set initialized
    the.element.setAttribute("data-kt-drawer", "true");

    // Event Handlers
    _handlers();

    // Update Instance
    _update();

    // Bind Instance
    KTUtil.data(the.element).set("drawer", the);
  };

  var _handlers = function () {
    var togglers = _getOption("toggle");
    var closers = _getOption("close");

    if (togglers !== null && togglers.length > 0) {
      KTUtil.on(document.body, togglers, "click", function (e) {
        e.preventDefault();

        the.toggleElement = this;
        _toggle();
      });
    }

    if (closers !== null && closers.length > 0) {
      KTUtil.on(document.body, closers, "click", function (e) {
        e.preventDefault();

        the.closeElement = this;
        _hide();
      });
    }
  };

  var _toggle = function () {
    if (
      KTEventHandler.trigger(the.element, "kt.drawer.toggle", the) === false
    ) {
      return;
    }

    if (the.shown === true) {
      _hide();
    } else {
      _show();
    }

    KTEventHandler.trigger(the.element, "kt.drawer.toggled", the);
  };

  var _hide = function () {
    if (KTEventHandler.trigger(the.element, "kt.drawer.hide", the) === false) {
      return;
    }

    the.shown = false;

    _deleteOverlay();

    document.body.removeAttribute("data-kt-drawer-" + the.name, "on");
    document.body.removeAttribute("data-kt-drawer");

    KTUtil.removeClass(the.element, the.options.baseClass + "-on");

    if (the.toggleElement !== null) {
      KTUtil.removeClass(the.toggleElement, "active");
    }

    KTEventHandler.trigger(the.element, "kt.drawer.after.hidden", the) ===
      false;
  };

  var _show = function () {
    if (KTEventHandler.trigger(the.element, "kt.drawer.show", the) === false) {
      return;
    }

    the.shown = true;

    _createOverlay();
    document.body.setAttribute("data-kt-drawer-" + the.name, "on");
    document.body.setAttribute("data-kt-drawer", "on");

    KTUtil.addClass(the.element, the.options.baseClass + "-on");

    if (the.toggleElement !== null) {
      KTUtil.addClass(the.toggleElement, "active");
    }

    KTEventHandler.trigger(the.element, "kt.drawer.shown", the);
  };

  var _update = function () {
    var width = _getWidth();
    var direction = _getOption("direction");

    var top = _getOption("top");
    var bottom = _getOption("bottom");
    var start = _getOption("start");
    var end = _getOption("end");

    // Reset state
    if (
      KTUtil.hasClass(the.element, the.options.baseClass + "-on") === true &&
      String(document.body.getAttribute("data-kt-drawer-" + the.name + "-")) ===
        "on"
    ) {
      the.shown = true;
    } else {
      the.shown = false;
    }

    // Activate/deactivate
    if (_getOption("activate") === true) {
      KTUtil.addClass(the.element, the.options.baseClass);
      KTUtil.addClass(the.element, the.options.baseClass + "-" + direction);

      KTUtil.css(the.element, "width", width, true);
      the.lastWidth = width;

      if (top) {
        KTUtil.css(the.element, "top", top);
      }

      if (bottom) {
        KTUtil.css(the.element, "bottom", bottom);
      }

      if (start) {
        if (KTUtil.isRTL()) {
          KTUtil.css(the.element, "right", start);
        } else {
          KTUtil.css(the.element, "left", start);
        }
      }

      if (end) {
        if (KTUtil.isRTL()) {
          KTUtil.css(the.element, "left", end);
        } else {
          KTUtil.css(the.element, "right", end);
        }
      }
    } else {
      KTUtil.removeClass(the.element, the.options.baseClass);
      KTUtil.removeClass(the.element, the.options.baseClass + "-" + direction);

      KTUtil.css(the.element, "width", "");

      if (top) {
        KTUtil.css(the.element, "top", "");
      }

      if (bottom) {
        KTUtil.css(the.element, "bottom", "");
      }

      if (start) {
        if (KTUtil.isRTL()) {
          KTUtil.css(the.element, "right", "");
        } else {
          KTUtil.css(the.element, "left", "");
        }
      }

      if (end) {
        if (KTUtil.isRTL()) {
          KTUtil.css(the.element, "left", "");
        } else {
          KTUtil.css(the.element, "right", "");
        }
      }

      _hide();
    }
  };

  var _createOverlay = function () {
    if (_getOption("overlay") === true) {
      the.overlayElement = document.createElement("DIV");

      KTUtil.css(
        the.overlayElement,
        "z-index",
        KTUtil.css(the.element, "z-index") - 1
      ); // update

      document.body.append(the.overlayElement);

      KTUtil.addClass(the.overlayElement, _getOption("overlay-class"));

      KTUtil.addEvent(the.overlayElement, "click", function (e) {
        e.preventDefault();

        if (_getOption("permanent") !== true) {
          _hide();
        }
      });
    }
  };

  var _deleteOverlay = function () {
    if (the.overlayElement !== null) {
      KTUtil.remove(the.overlayElement);
    }
  };

  var _getOption = function (name) {
    if (the.element.hasAttribute("data-kt-drawer-" + name) === true) {
      var attr = the.element.getAttribute("data-kt-drawer-" + name);
      var value = KTUtil.getResponsiveValue(attr);

      if (value !== null && String(value) === "true") {
        value = true;
      } else if (value !== null && String(value) === "false") {
        value = false;
      }

      return value;
    } else {
      var optionName = KTUtil.snakeToCamel(name);

      if (the.options[optionName]) {
        return KTUtil.getResponsiveValue(the.options[optionName]);
      } else {
        return null;
      }
    }
  };

  var _getWidth = function () {
    var width = _getOption("width");

    if (width === "auto") {
      width = KTUtil.css(the.element, "width");
    }

    return width;
  };

  var _destroy = function () {
    KTUtil.data(the.element).remove("drawer");
  };

  // Construct class
  _construct();

  ///////////////////////
  // ** Public API  ** //
  ///////////////////////

  // Plugin API
  the.toggle = function () {
    return _toggle();
  };

  the.show = function () {
    return _show();
  };

  the.hide = function () {
    return _hide();
  };

  the.isShown = function () {
    return the.shown;
  };

  the.update = function () {
    _update();
  };

  the.goElement = function () {
    return the.element;
  };

  the.destroy = function () {
    return _destroy();
  };

  // Event API
  the.on = function (name, handler) {
    return KTEventHandler.on(the.element, name, handler);
  };

  the.one = function (name, handler) {
    return KTEventHandler.one(the.element, name, handler);
  };

  the.off = function (name, handlerId) {
    return KTEventHandler.off(the.element, name, handlerId);
  };

  the.trigger = function (name, event) {
    return KTEventHandler.trigger(the.element, name, event, the, event);
  };
};

// Static methods
KTDrawer.getInstance = function (element) {
  if (element !== null && KTUtil.data(element).has("drawer")) {
    return KTUtil.data(element).get("drawer");
  } else {
    return null;
  }
};

// Hide all drawers and skip one if provided
KTDrawer.hideAll = function (
  skip = null,
  selector = '[data-kt-drawer="true"]'
) {
  var items = document.querySelectorAll(selector);

  if (items && items.length > 0) {
    for (var i = 0, len = items.length; i < len; i++) {
      var item = items[i];
      var drawer = KTDrawer.getInstance(item);

      if (!drawer) {
        continue;
      }

      if (skip) {
        if (item !== skip) {
          drawer.hide();
        }
      } else {
        drawer.hide();
      }
    }
  }
};

// Update all drawers
KTDrawer.updateAll = function (selector = '[data-kt-drawer="true"]') {
  var items = document.querySelectorAll(selector);

  if (items && items.length > 0) {
    for (var i = 0, len = items.length; i < len; i++) {
      var drawer = KTDrawer.getInstance(items[i]);

      if (drawer) {
        drawer.update();
      }
    }
  }
};

// Create instances
KTDrawer.createInstances = function (selector = '[data-kt-drawer="true"]') {
  // Initialize Menus
  var elements = document.querySelectorAll(selector);

  if (elements && elements.length > 0) {
    for (var i = 0, len = elements.length; i < len; i++) {
      new KTDrawer(elements[i]);
    }
  }
};

// Toggle instances
KTDrawer.handleShow = function () {
  // External drawer toggle handler
  KTUtil.on(
    document.body,
    '[data-kt-drawer-show="true"][data-kt-drawer-target]',
    "click",
    function (e) {
      e.preventDefault();

      var element = document.querySelector(
        this.getAttribute("data-kt-drawer-target")
      );

      if (element) {
        KTDrawer.getInstance(element).show();
      }
    }
  );
};

// Handle escape key press
KTDrawer.handleEscapeKey = function () {
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      //if esc key was not pressed in combination with ctrl or alt or shift
      const isNotCombinedKey = !(
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
      );
      if (isNotCombinedKey) {
        var elements = document.querySelectorAll(
          '.drawer-on[data-kt-drawer="true"]:not([data-kt-drawer-escape="false"])'
        );
        var drawer;

        if (elements && elements.length > 0) {
          for (var i = 0, len = elements.length; i < len; i++) {
            drawer = KTDrawer.getInstance(elements[i]);
            if (drawer.isShown()) {
              drawer.hide();
            }
          }
        }
      }
    }
  });
};

// Dismiss instances
KTDrawer.handleDismiss = function () {
  // External drawer toggle handler
  KTUtil.on(
    document.body,
    '[data-kt-drawer-dismiss="true"]',
    "click",
    function (e) {
      var element = this.closest('[data-kt-drawer="true"]');

      if (element) {
        var drawer = KTDrawer.getInstance(element);
        if (drawer.isShown()) {
          drawer.hide();
        }
      }
    }
  );
};

// Handle resize
KTDrawer.handleResize = function () {
  // Window resize Handling
  window.addEventListener("resize", function () {
    var timer;

    KTUtil.throttle(
      timer,
      function () {
        // Locate and update drawer instances on window resize
        var elements = document.querySelectorAll('[data-kt-drawer="true"]');

        if (elements && elements.length > 0) {
          for (var i = 0, len = elements.length; i < len; i++) {
            var drawer = KTDrawer.getInstance(elements[i]);
            if (drawer) {
              drawer.update();
            }
          }
        }
      },
      200
    );
  });
};

// Global initialization
KTDrawer.init = function () {
  KTDrawer.createInstances();

  if (KTDrawerHandlersInitialized === false) {
    KTDrawer.handleResize();
    KTDrawer.handleShow();
    KTDrawer.handleDismiss();
    KTDrawer.handleEscapeKey();

    KTDrawerHandlersInitialized = true;
  }
};

document.addEventListener("turbo:load", KTDrawer.init);

export default KTDrawer;
