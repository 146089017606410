import { application } from "./application"
import { Autocomplete } from 'stimulus-autocomplete'
import AutoSubmit from "@stimulus-components/auto-submit";
import NestedForm from 'stimulus-rails-nested-form'
import StimulusControllerResolver from 'stimulus-controller-resolver'

application.register("autocomplete", Autocomplete)
application.register("auto-submit", AutoSubmit);
application.register("nested-form", NestedForm)

const controllerPrefixes = {
  hyk: "admin/",
  mtc: "metronic/",
  inv: "platform/",
}

const generateControllerName = (controllerName) => {
  const nameParts = controllerName.split('-')
  const prefix = controllerPrefixes[nameParts[0]]

  if (prefix) {
    nameParts.shift()
    nameParts.unshift(prefix)
  }

  return nameParts.join('_').replaceAll('/_', '/')
}

StimulusControllerResolver.install(application, async controllerName => (
  (await import(`./${generateControllerName(controllerName)}_controller`)).default
))
