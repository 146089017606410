import LocalTime from "local-time";

LocalTime.config.i18n.pt = {
  date: {
    dayNames: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    abbrDayNames: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    abbrMonthNames: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    yesterday: "ontem",
    today: "hoje",
    tomorrow: "amanhã",
    on: "em {date}",
    formats: {
      default: "%d/%m/%Y",
      thisYear: "%d de %B",
    },
  },
  time: {
    am: "AM",
    pm: "PM",
    singular: "{time} atrás",
    singularAn: "{time} atrás",
    elapsed: "{time} atrás",
    second: "segundo",
    seconds: "segundos",
    minute: "minuto",
    minutes: "minutos",
    hour: "hora",
    hours: "horas",
    formats: {
      default: "%H:%M",
      default_24h: "%H:%M",
    },
  },
  datetime: {
    at: "{date} às {time}",
    formats: {
      default: "%d de %B de %Y às %H:%M %Z",
      default_24h: "%d de %B de %Y às %H:%M %Z",
    },
  },
};

LocalTime.config.locale = "pt";
LocalTime.config.useFormat24 = true;

export default LocalTime;
